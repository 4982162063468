"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Account = function Account() {

    'use strict';

    /**
     * jQuery elements
     * @private
     */

    /**
     * Has the class been initialized?
     * @private
     */

    var inited = false;

    var count = 0;

    /**
     * Initializes the class.
     * @public
     */
    var init = function init() {

        // Abort if already initialized
        if (inited) {
            return false;
        }

        inited = true;

        var allInput = document.querySelectorAll(".form-input");
        var buttonSave = document.querySelectorAll(".saveAccount");

        if (allInput.length != 0 && buttonSave.length != 0) {
            allInput.forEach(function (input) {
                input.addEventListener("change", function () {
                    buttonSave[0].removeAttribute("disabled");
                });
                input.addEventListener("keyup", function () {
                    buttonSave[0].removeAttribute("disabled");
                });
            });
        }

        var schoolBoardSelect = document.querySelector('[data-select-schoolBoard]');

        if (schoolBoardSelect) {
            var schoolSelect = document.querySelector('[data-select-school]');
            if (schoolSelect) {
                if (schoolBoardSelect.value !== '') {
                    var schoolBoardId = schoolBoardSelect.value;
                    schoolSelect.closest('.school-select').classList.remove('is-hidden');
                    axios.get("/actions/site-module/default/get-schools-from-school-board?schoolBoardId=" + schoolBoardId).then(function (response) {
                        if (response.data.success) {
                            var schools = response.data.schools;

                            var schoolsOptions = schools.map(function (school) {
                                var option = "<option value=\"" + school.id + "\"";

                                // Vérifiez si school.regionId existe avant d'ajouter l'attribut data
                                if (school.regionId) {
                                    option += " data-school-region=\"" + school.regionId + "\"";
                                }

                                option += ">" + school.title + "</option>";
                                return option;
                            }).join('');

                            schoolSelect.innerHTML = schoolsOptions;

                            var refreshOptions = schoolSelect.querySelectorAll('option');
                            if (refreshOptions.length === 2) {
                                refreshOptions[1].selected = true;
                            }

                            if (schoolSelect.dataset.userSchool) {
                                refreshOptions.forEach(function (option) {
                                    if (option.value === schoolSelect.dataset.userSchool) {
                                        option.selected = true;
                                    }
                                });
                            }

                            var regionInput = document.querySelector('[name="fields[regions][]"]');

                            if (regionInput) {
                                regionInput.value = refreshOptions[1].dataset.schoolRegion;
                            }
                        }
                    });
                }

                $('[data-select-schoolBoard]').on('select2:select', function (e) {
                    var schoolBoardId = e.target.value;
                    schoolSelect.closest('.school-select').classList.remove('is-hidden');
                    axios.get("/actions/site-module/default/get-schools-from-school-board?schoolBoardId=" + schoolBoardId).then(function (response) {
                        if (response.data.success) {
                            var schools = response.data.schools;

                            var schoolsOptions = schools.map(function (school) {
                                var option = "<option value=\"" + school.id + "\"";

                                // Vérifiez si school.regionId existe avant d'ajouter l'attribut data
                                if (school.regionId) {
                                    option += " data-school-region=\"" + school.regionId + "\"";
                                }

                                option += ">" + school.title + "</option>";
                                return option;
                            }).join('');

                            schoolSelect.innerHTML = schoolsOptions;

                            var refreshOptions = schoolSelect.querySelectorAll('option');
                            if (refreshOptions.length === 2) {
                                refreshOptions[1].selected = true;
                            }

                            var regionInput = document.querySelector('[name="fields[regions][]"]');

                            if (regionInput) {
                                regionInput.value = refreshOptions[1].dataset.schoolRegion;
                            }
                        }
                    });
                });

                schoolBoardSelect.addEventListener('change', function (e) {
                    var schoolBoardId = e.target.value;
                    schoolSelect.closest('.school-select').classList.remove('is-hidden');
                    axios.get("/actions/site-module/default/get-schools-from-school-board?schoolBoardId=" + schoolBoardId).then(function (response) {
                        if (response.data.success) {
                            var schools = response.data.schools;

                            var schoolsOptions = schools.map(function (school) {
                                var option = "<option value=\"" + school.id + "\"";

                                // Vérifiez si school.regionId existe avant d'ajouter l'attribut data
                                if (school.regionId) {
                                    option += " data-school-region=\"" + school.regionId + "\"";
                                }

                                option += ">" + school.title + "</option>";
                                return option;
                            }).join('');

                            schoolSelect.innerHTML = schoolsOptions;

                            var refreshOptions = schoolSelect.querySelectorAll('option');
                            if (refreshOptions.length === 2) {
                                refreshOptions[1].selected = true;
                            }

                            var regionInput = document.querySelector('[name="fields[regions][]"]');

                            if (regionInput) {
                                regionInput.value = refreshOptions[1].dataset.schoolRegion;
                            }
                        }
                    });
                });

                schoolSelect.addEventListener('change', function (e) {
                    var regionInput = document.querySelector('[name="fields[regions][]"]');

                    if (regionInput) {
                        regionInput.value = e.target.options[e.target.selectedIndex].dataset.schoolRegion;
                    }
                });
            }
        }

        $('.js-select2').select2({
            language: "es"
        });

        var passwordToggles = document.querySelectorAll('[data-password-toggle]');

        passwordToggles.forEach(function (toggle) {
            var input = toggle.closest('.input-wrapper').querySelector('input[type="password"]');
            var checkbox = toggle.querySelector('input');
            var iconHide = toggle.querySelector('.password-hide');
            var iconShow = toggle.querySelector('.password-show');
            if (input && checkbox && iconHide && iconShow) {
                toggle.addEventListener('click', function (e) {
                    e.preventDefault();
                    checkbox.checked = !checkbox.checked;
                    if (checkbox.checked) {
                        input.type = 'text';
                        iconHide.classList.remove('is-hidden');
                        iconShow.classList.add('is-hidden');
                    } else {
                        input.type = 'password';
                        iconHide.classList.add('is-hidden');
                        iconShow.classList.remove('is-hidden');
                    }
                });
            }
        });

        return true;
    };

    // Expose public methods & properties
    return {
        init: init
    };
}();